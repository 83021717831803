import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const BlogPageTemplate = ({ data: { menuItems, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  const schemaOrg = [
    {
        "@id": "Organization",
        "@context": "http://schema.org",
        "@type": "EducationalOrganization",
        "image": "",
        "name": "Uma Manualidades",
        "description": "Manualidades, tutoriales y proyectos para todos los públicos.",
        "url": "https://www.umamanualidades.com/",
        "logo": {
            "@type": "ImageObject",
            "url": ""
        },
        "sameAs": ["https://www.facebook.com/umamanualidades/", "https://www.instagram.com/umamanualidades/", "https://www.pinterest.es/umamanualidades/"]
    }
  ]
  console.log(post);
  return (
    <Layout>
    <Helmet>
        <link rel="icon" href="https://www.umadecoracion.com/wp-content/uploads/2022/12/cropped-uma-decoracion-32x32.jpg" sizes="32x32" />
        <link rel="icon" href="https://www.umadecoracion.com/wp-content/uploads/2022/12/cropped-uma-decoracion-192x192.jpg" sizes="192x192" />
        <link rel="apple-touch-icon" href="https://www.umadecoracion.com/wp-content/uploads/2022/12/cropped-uma-decoracion-180x180.jpg" />
        <meta name="msapplication-TileImage" content="https://www.umadecoracion.com/wp-content/uploads/2022/12/cropped-uma-decoracion-270x270.jpg" />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrg)}
        </script>
    </Helmet>
      <Seo title={post.title} description={post.excerpt} url={post.uri} />
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
      <h1 className="entry-title" itemProp="headline">{parse(post.title)}</h1>

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}
      </article>
    </Layout>
  )
}
export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageById(
    $id: String!
  ) {
    post: wpPage(id: { eq: $id }) {
      id
      content
      uri
      title
      seo {
        title
        canonical
        metaDesc
      }
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
